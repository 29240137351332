<template>
  <div>
  <!--订单详情  -->
  <el-row style="margin:20px 400px;">
    <div class="orderInfo">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: 'myOrderList' }">我的运单</el-breadcrumb-item>
        <el-breadcrumb-item>我的运单</el-breadcrumb-item>
        <el-breadcrumb-item v-if="routeName == 1">我的发货</el-breadcrumb-item>
        <el-breadcrumb-item v-else>我的收货</el-breadcrumb-item>
        <el-breadcrumb-item>订单详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-col style="margin-top: 30px;display: flex;font-size: 13px">
      <p style="color: #999999;">运单编号:</p>
      {{orderInfo.orderNo}}
      <i class="el-icon-copy-document" style="margin-left:3px;margin-top: 3px;cursor: pointer" @click="copy"></i>
      <el-button size="mini" type="primary" style="margin-left: 30px" @click="$router.push({ name: 'myOrderList' })">返回列表</el-button>
    </el-col>

  </el-row>
  <el-row style="margin:20px 400px;text-align: center;">
    <el-col :span="12" style="display: flex;flex-direction: row;justify-content: center;margin-top: 2%">
      <div style="display: flex;flex-direction: column;">
        <span class="bold-text">{{orderInfo.deliveryOrgName}}</span>
        <span style="font-size: 12px;color: #333333;">{{orderInfo.deliveryUserName}}</span>
      </div>
      <div style="display: flex;flex-direction: column;margin:auto 120px">
        <img src="../assets/img/fw.png"  style="width: 54px;height: 7px;margin-right: 3%;"/>
        <span style="font-size: 12px;color: #333333;">{{orderInfo.messagesStatus}}</span>
      </div>
      <div style="display: flex;flex-direction: column;">
        <span class="bold-text">{{orderInfo.receiveOrgName}}</span>
        <span style="font-size: 12px;color: #333333;">{{orderInfo.receiveUserName}}</span>
      </div>
    </el-col>

    <el-col :span="12" style="display: flex;flex-direction: column;text-align: left;padding-left: 10%">
      <div style="display: flex;line-height: 30px;align-items: center;margin-top:20px">
        <div style="width: 7px;height: 7px;background: #198cff;border-radius: 50%;margin-right:5%"></div>
        <div>
          <div class="bold-text" style="line-height: 24px">{{orderInfo.deliveryUserName}}</div>
          <div style="font-size: 12px;color: #777777;line-height: 16px;">{{orderInfo.deliveryAddress}}</div>
        </div>
      </div>

      <div style="display: flex;line-height: 30px;align-items: center;margin-top:20px">
        <div style="width: 7px;height: 7px;background: #FE5959;border-radius: 50%;margin-right:5%"></div>
        <div style="width: 100%">
          <div class="bold-text" style="line-height: 24px">{{orderInfo.receiveUserName}}</div>
          <div style="font-size: 12px;color: #777777;line-height: 16px;">{{orderInfo.recipientsAddress}}</div>
        </div>
      </div>
    </el-col>

    <div style="display: flex;width: 100%">
      <div style="width: 60%">
        <div class="block" style="text-align: left">
          <el-timeline>
            <el-timeline-item
                placement="top"
                v-for="(activity, index) in route"
                :key="index"
                :icon="activity.icon"
                :type="activity.type"
                :color="activity.color"
                :size="activity.size"
                :timestamp="todateTimelocal(activity.createTime)">
              {{activity.routeDescription}}
              <p style="position: absolute;top:2px;left:-10%">{{activity.routeNode}}</p>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
      <div style="width: 40%;margin-top:40px;">
        <el-row>
          <el-col :span="24"><div style="margin-bottom: 30px;float: left">{{orderInfo.goodsName}}</div></el-col>
          <el-col :span="24">
            <span  style="font-size: 14px;color: #777777;float: left">数量</span>
            <span class="pull-right">{{orderInfo.goodsCount}}</span>
          </el-col>
          <el-col :span="24" style="margin-top:5px">
            <span  style="font-size: 14px;color: #777777;float: left">编号</span>
            <span class="pull-right">{{orderInfo.goodsCount}}</span>
          </el-col>
          <el-col :span="24" style="margin-top:5px">
            <span  style="font-size: 14px;color: #777777;float: left">包装</span>
            <span class="pull-right">{{optionsPackaging(orderInfo.packageId)}}</span>
          </el-col>
          <el-col :span="24" style="margin-top:5px">
            <span  style="font-size: 14px;color: #777777;float: left">重量</span>
            <span class="pull-right">{{orderInfo.goodsWeight}}</span>
          </el-col>
          <el-col :span="24" style="margin-top:5px">
            <span  style="font-size: 14px;color: #777777;float: left">体积</span>
            <span class="pull-right">{{orderInfo.goodsVolume}}</span>
          </el-col>
          <el-col :span="24" style="margin-top:5px">
            <span  style="font-size: 14px;color: #777777;float: left">图片</span>
            <div class="pull-right" style="display: flex;" v-for="(item,index) in this.picList" :key="index">
              <img :src="resourceUrl+ '?id=' +item" style="width: 100px;height: 100px;margin-left: 10px;" />
            </div>
          </el-col>

        </el-row>

      </div>
    </div>
  </el-row>
  </div>
</template>

<script>
import { findOrderId } from "@/api/orderInfo";
export default {
  components: {

  },
  data() {
    return {
      id:'',
      orderInfo:'',
      route:[],
      picList:[],
      routeName:1,
    };
  },
  created() {

  },
  mounted() {
    if(this.$route.params.data == undefined){
      this.$router.push({ name: 'home' })
    }else{
      this.orderInfo = this.$route.params.data;
      this.routeName = this.$route.params.data.routeName;
      if(this.orderInfo.pic!=null){
        this.picList = this.orderInfo.pic.split(",");
      }
      this.getOrderDetail(this.orderInfo.orderId)
    }

  },
  methods: {
    //route
    getOrderDetail(id){
      findOrderId({id:id}).then((res) => {
        if (res.code === 200) {
          this.route = res.data.route;
        }
      })
    },
    optionsPackaging(value) {
      if (!value) return '';
      if (value === 1) {
        value = "纸箱"
      } else if (value === 2) {
        value = "编织袋"
      } else if (value === 3) {
        value = "泡沫箱"
      } else if (value === 4) {
        value = "木箱"
      } else if (value === 5) {
        value = "塑料袋"
      } else if (value === 6) {
        value = "回单"
      } else if (value === 7) {
        value = "无"
      } else if (value === 8) {
        value = "纸"
      } else if (value === 9) {
        value = "袋"
      } else if (value === 10) {
        value = "桶"
      } else if (value === 11) {
        value = "木"
      } else if (value === 12) {
        value = "膜"
      } else if (value === 13) {
        value = "托盘"
      }
      return value;
    },
    copy(){
      let domUrl = document.createElement("input"); //创建一个input
      domUrl.value = this.orderInfo.orderNo;
      domUrl.id ="creatDom";
      document.body.appendChild(domUrl);
      domUrl.select(); // 选择对象
      document.execCommand("copy"); // 执行浏览器复制命令
      let creatDom = document.getElementById("creatDom");
      creatDom.parentNode.removeChild(creatDom);
      this.$message({
        message: '复制成功',
        type: 'success'
      });
    },
    //时间戳转日期＋时间
    todateTimelocal(timeStamp) {
      if (timeStamp === '' || timeStamp === null || timeStamp === undefined || timeStamp === '0' || timeStamp === 0) return '';
      var date = new Date();
      date.setTime(timeStamp);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? ('0' + m) : m;
      var d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      var h = date.getHours();
      h = h < 10 ? ('0' + h) : h;
      var minute = date.getMinutes();
      var second = date.getSeconds();
      minute = minute < 10 ? ('0' + minute) : minute;
      second = second < 10 ? ('0' + second) : second;
      return timeStamp = y + '-' + m + '-' + d + '   ' + h + ':' + minute + ':' + second;
    },
  }
};
</script>

<style lang="scss" scoped>
.bold-text{
  font-size: 18px;
  font-weight: bold;
}
.pull-left{
  float: left;
}
.pull-right{
  float: right;
}
</style>
